import React from 'react';
import Select from 'react-select';
import { FormHelperText } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import { customStyles } from './styles';

const CustomSelectPDFModel = ({
  name,
  field,
  placeholder,
  handleChange,
  value,
  menuList,
  errors,
  isMulti = false,
  isClearable = true,
  touched,
  handleBlur = () => {},
  setFieldTouched = () => {},
  options, // Nova prop para receber as opções
}) => {
  return (
    <>
      <Select
        placeholder={placeholder}
        styles={customStyles(menuList)}
        name={name}
        options={options} // Usar as opções passadas como prop
        onChange={handleChange}
        value={value}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        isMulti={isMulti}
        isClearable={isClearable}
        onBlur={handleBlur}
        onFocus={() => setFieldTouched(name, true, false)}
        error={errors && errors[name] && touched[name]}
      />
      {field ? (
        <ErrorMessage name={field.name}>
          {() => (
            <FormHelperText error id="component-error-text">
              {errors}
            </FormHelperText>
          )}
        </ErrorMessage>
      ) : (
        <>
          {errors && (
            <ErrorMessage name={name}>
              {() => (
                <FormHelperText error id="component-error-text">
                  {errors}
                </FormHelperText>
              )}
            </ErrorMessage>
          )}
        </>
      )}
    </>
  );
};

export default CustomSelectPDFModel;
