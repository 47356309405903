import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FooterButton from './FooterButton';
import ModalResponseSuccess from './ModalResponseSuccess';
import { ContainerFormModal, InputContainer, InputItem } from './styles';
import CustomSelectUnits from 'shared/component/forms/CustomSelectUnits';
import CalendarMonthYearPicker from 'shared/component/CalendarMonthYearPicker';
import CustomSelectUnitScales from 'shared/component/forms/CustomSelectUnitScales';

import { useBookPresence } from 'hook/agenda/presence_book';
import MessageNotification from 'shared/component/messageNotification';
import SwitchComponent from 'shared/component/switchComponent';
import CustomSelectSubsidiaries from 'shared/component/forms/CustomSelectSubsidiaries';
import CustomSelectPDFModel from 'shared/component/forms/CustomSelectPDFModel';

export const formInitialValues = {
  empty: false,
  report_checkin: false,
  unit: { id: '' },
  scales: '',
  monthYear: {
    year: '',
    month: '',
  },
  pdfModel: '',
};

const FormPresenceBook = ({ handleCloseModal }) => {
  const { loading, getAgendaPdf } = useBookPresence();
  const [error, setError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(null);
  const [message, setMessage] = useState(null);
  const pdfModelOptions = [
    { value: 'v1', label: 'modelo 1' },
    { value: 'v2', label: 'modelo 2' },
  ];

  const handleSubmit = async (data) => {
    const params = {
      subsidiary_id: data.subsidiary?.value ?? undefined,
      unit_id: data.unit.id,
      year: data.monthYear.year,
      month: data.monthYear.month,
      empty: data.empty === true ? 0 : 1,
      report_checkin: data.report_checkin === true ? 1 : 0,
      pdf_model: data?.pdfModel ?? 'v1',
    };

    await getAgendaPdf(params, data.scales)
      .then(() => {
        setModalSuccess(true);
        setMessage(null);
        setError(false);
      })
      .catch((errorMessage) => {
        setMessage(errorMessage);
        setError(true);
      });
  };

  const [selectedScales, setSelectedScales] = useState(null);
  const schema = Yup.object().shape({
    subsidiary: Yup.object().shape({
      id: Yup.number(),
    }),
    unit: Yup.object().shape({
      id: Yup.number().typeError('Obrigatório!').required('Obrigatório!'),
    }),
    scales: Yup.array().typeError('Obrigatório!').required('Obrigatório!'),
    monthYear: Yup.object().shape({
      year: Yup.number().typeError('Obrigatório!').required('Obrigatório!'),
      month: Yup.number().typeError('Obrigatório!').required('Obrigatório!'),
    }),
    empty: Yup.boolean(),
    pdfModel: Yup.string().required('Obrigatório!'), // Validação para o novo campo
  });

  return (
    <ContainerFormModal>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={formInitialValues}
        validateOnBlur
      >
        {({
          setFieldValue,
          setFieldTouched,
          errors,
          values,
          handleBlur,
          touched,
        }) => (
          <Form>
            <InputContainer>
              <InputItem data-cy="select_hospital">
                <Field
                  name="subsidiary"
                  style={{ width: '100%' }}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  placeholder={'Selecione uma filial'}
                  label={'Selecione uma filial'}
                  errors={errors && errors?.unit}
                  handleChange={(data) => {
                    if (data?.value) setFieldValue('subsidiary', data);
                    else setFieldValue('subsidiary', '');
                  }}
                  value={values.subsidiary}
                  menuList={() => ({ height: 116, overflowY: 'auto' })}
                  variant="outlined"
                  component={CustomSelectSubsidiaries}
                  isClearable
                />
              </InputItem>
            </InputContainer>

            <InputContainer>
              <InputItem data-cy="select_hospital">
                <Field
                  name="unit"
                  style={{ width: '100%' }}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  placeholder={'Selecione o hospital'}
                  label={'Selecione o hospital'}
                  errors={errors && errors?.unit?.id}
                  handleChange={(data) => {
                    setFieldValue('scales', '');
                    setSelectedScales(null);
                    if (data?.value) setFieldValue('unit.id', data.value);
                    else setFieldValue('unit.id', '');
                  }}
                  menuList={() => ({ height: 116, overflowY: 'auto' })}
                  variant="outlined"
                  component={CustomSelectUnits}
                  isClearable
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <Field
                  name="scales"
                  style={{ width: '100%' }}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  placeholder={'Selecione a escala'}
                  label={'Selecione a escala'}
                  value={selectedScales}
                  errors={errors && errors?.scales}
                  handleChange={(data) => {
                    setFieldValue('scales', data);
                    setSelectedScales(data);
                  }}
                  menuList={() => ({ height: 116, overflowY: 'auto' })}
                  variant="outlined"
                  component={CustomSelectUnitScales}
                  unit_id={values?.unit?.id && values.unit.id}
                  isMulti
                  isClearable
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem data-cy="select_pdf_model">
                <Field
                  name="pdfModel"
                  style={{ width: '100%' }}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  placeholder={'Versão do caderno de presença'}
                  label={'Versão do caderno de presença'}
                  errors={errors && errors?.pdfModel}
                  handleChange={(data) => {
                    if (data?.value) setFieldValue('pdfModel', data.value);
                    else setFieldValue('pdfModel', '');
                  }}
                  options={pdfModelOptions}
                  value={
                    pdfModelOptions.find(
                      (option) => option.value === values.pdfModel
                    ) || null
                  }
                  menuList={() => ({ height: 116, overflowY: 'auto' })}
                  variant="outlined"
                  component={CustomSelectPDFModel}
                  isClearable
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem data-cy="containerCalendar">
                <Field
                  name="monthYear"
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.monthYear}
                  errors={errors && errors?.monthYear?.month}
                  touched={touched}
                  label="Mês/Ano"
                  variant="outlined"
                  component={CalendarMonthYearPicker}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem data-cy="containerCalendar">
                <span style={{ marginRight: 10 }}>Com assinatura?</span>

                <Field
                  name="empty"
                  active="Sim"
                  inactive="Não"
                  value={values.empty}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  component={SwitchComponent}
                  st
                  // onChange={handleChange}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem data-cy="containerCalendar">
                <span style={{ marginRight: 10 }}>Com Checkin e checkout?</span>

                <Field
                  name="report_checkin"
                  active="Sim"
                  inactive="Não"
                  value={values.report_checkin}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  component={SwitchComponent}
                  st
                  // onChange={handleChange}
                />
              </InputItem>
            </InputContainer>
            <FooterButton
              loading={loading}
              handleCloseModal={handleCloseModal}
            />
          </Form>
        )}
      </Formik>
      <MessageNotification
        openNotification={error}
        closeNotification={() => setError(false)}
        type={'error'}
        message={message}
        vertical={10}
        horizontal="40vw"
      />
      <ModalResponseSuccess
        openModalSuccess={modalSuccess}
        handleCloseModalSuccess={() => setModalSuccess(false)}
      />
    </ContainerFormModal>
  );
};

export default FormPresenceBook;
