/* eslint-disable no-prototype-builtins */
import { useState } from 'react';
import api from 'service/api';
import { useAuth } from './auth';
import fileDownload from 'js-file-download';

const useReport = () =>
  // { page = 1, perPage = 12, search = '' } = {
  //   page: 1,
  //   perPage: 12,
  //   search: '',
  // }
  {
    const { requestIntercept } = useAuth();
    const [dataProfessional, setDataProfessional] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalProfessional, setTotalProfessional] = useState(0);
    const [reports, setReports] = useState({
      list: [],
      loading: false,
      error: null,
      pagination: '',
      status: '',
      msg: '',
      type_remuneration: null,
      page: 1,
      perPage: 12,
    });
    const [reportsCheck, setReportsCheck] = useState({
      data: [],
      loadingCheckReports: false,
      error: null,
      pagination: '',
      status: '',
      msg: '',
      page: 1,
      perPage: 12,
    });
    const [checkWithDetails, setCheckWithDetails] = useState({
      listCheckDetails: [],
      loadingCheckDetails: false,
      errorCheckDetails: null,
      paginationCheckDetails: '',
      statusCheckDetails: '',
      msgCheckDetails: '',
    });
    const [reportsWithDetails, setReportsWithDetails] = useState({
      listWithDetails: '',
      loadingWithDetails: false,
      errorWithDetails: null,
      paginationWithDetails: '',
      statusWithDetails: '',
      msgWithDetails: '',
    });

    const serialize = (obj) => {
      const str = [];
      for (const p in obj) {
        if (obj.hasOwnProperty(p) && obj[p] !== null) {
          str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
        }
      }
      return str.join('&');
    };

    let scaleQry = '';
    let situationStatusQry = '';

    const getCheckInReports = async ({
      page,
      perPage,
      search,
      unitId,
      dateStart,
      dateEnd,
      filter,
      situationStatus,
      scaleId,
    }) => {
      if (filter === 'universal') {
        scaleQry = '';
        situationStatusQry = '';
      }
      setReportsCheck({ ...reportsCheck, loadingCheckReports: true });
      scaleId.forEach((scale) => {
        scaleQry = `${scaleQry}&scale_id=${scale.id}`;
      });
      situationStatus.forEach((situationStatus) => {
        situationStatusQry = `${situationStatusQry}&custom_filter=${situationStatus.id}`;
      });
      setReportsCheck({
        loadingCheckReports: true,
        error: false,
      });
      try {
        const response = await requestIntercept(
          api.get,
          `/v2/admin/reports/checkin?page=${page}&per_page=${perPage}${scaleQry}${situationStatusQry}`,
          {
            filter,
            search,
            unit_id: unitId,
            date_start: dateStart,
            date_end: dateEnd,
          }
        );
        if (response.status === 200 || response.status === 201) {
          setReportsCheck({
            ...reports,
            data: response.data.data,
            loadingCheckReports: false,
            pagination: response.data.pagination.total_items,
            status: response.status,
            msg: response.data.msg,
          });
        } else {
          throw response;
        }
      } catch (err) {
        setReportsCheck({
          loadingCheckReports: false,
          status: err.status,
          msg: err.msg,
        });
      }
    };
    const getCheckInReportsDetails = async ({
      page,
      perPage,
      unitId,
      dateStart,
      dateEnd,
      filter,
      userId,
    }) => {
      setCheckWithDetails({ ...checkWithDetails, loadingCheckDetails: true });
      try {
        const response = await requestIntercept(
          api.get,
          `/v2/admin/reports/checkin?page=${page}&per_page=${perPage}&date_start=${dateStart}&date_end=${dateEnd}&order=date_start&order_by=desc`,
          {
            filter,
            unit_id: unitId,
            user_id: userId,
          }
        );
        if (response.status === 200 || response.status === 201) {
          setCheckWithDetails({
            ...checkWithDetails,
            listCheckDetails: response.data.data,
            loadingCheckDetails: false,
            paginationCheckDetails: response.data.pagination.total_items,
            statusCheckDetails: response.status,
            msgCheckDetails: response.data.msg,
          });
        } else {
          throw response;
        }
      } catch (err) {
        setCheckWithDetails({
          ...checkWithDetails,
          loadingCheckDetails: false,
          statusCheckDetails: err.status,
          msgCheckDetails: err.msg,
        });
      }
    };

    const getProfessionalsWithoutAgenda = async (params) => {
      try {
        setLoading(true);
        const response = await requestIntercept(
          api.get,
          `/v2/admin/reports/user-without-agenda`,
          params
        );
        if (response.status !== 200) {
          throw response;
        }
        setDataProfessional(response.data.data);
        setTotalProfessional(response.data.pagination.total_items);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    const getProfessionalsWithoutApp = async (params) => {
      try {
        setLoading(true);
        const response = await requestIntercept(
          api.get,
          `/v2/admin/reports/user-without-application`,
          params
        );
        if (response.status !== 200) {
          throw response;
        }
        setDataProfessional(response.data.data);
        setTotalProfessional(response.data.pagination.total_items);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    const getReports = async ({
      page,
      perPage,
      search,
      unitId,
      dateStart,
      dateEnd,
      filter,
      situationStatus = [],
      scaleIds,
      userId,
      typeGroup,
      atSight,
      validated,
      company,
    }) => {
      if (filter === 'universal') {
        scaleQry = '';
        situationStatusQry = '';
      }
      setReports({ ...reports, loading: true });
      scaleIds.forEach((scale) => {
        scaleQry = `${scaleQry}&scale_id=${scale.id}`;
      });
      situationStatus.forEach((situationStatus) => {
        situationStatusQry = `${situationStatusQry}&custom_filter=${situationStatus.id}`;
      });
      try {
        const response = await requestIntercept(
          api.get,
          `/v2/admin/reports?page=${page}&per_page=${perPage}${scaleQry}${situationStatusQry}`,
          {
            filter,
            search,
            date_start: dateStart,
            date_end: dateEnd,
            unit_id: unitId !== null ? unitId : undefined,
            company_id: unitId === null ? company : undefined,
            user_id: userId,
            type_group: typeGroup,
            at_sight: atSight,
            validated,
          }
        );
        if (response.status === 200 || response.status === 201) {
          setReports({
            ...reports,
            list: response.data.data,
            loading: false,
            pagination: response.data.pagination,
            status: response.status,
            msg: response.data.msg,
          });
        } else {
          throw response;
        }
      } catch (err) {
        setReports({
          loading: false,
          status: err.status,
          msg: err.msg,
        });
      }
    };

    const getReportsWithDetails = async ({
      page,
      perPage,
      unitId,
      dateStart,
      dateEnd,
      filter,
      userId,
      scaleId,
      situationStatus = [],
    }) => {
      if (filter === 'universal') {
        scaleQry = '';
        situationStatusQry = '';
      }
      scaleId.forEach((scale) => {
        scaleQry = `${scaleQry}&scale_id=${scale.id}`;
      });
      situationStatus.forEach((situationStatus) => {
        situationStatusQry = `${situationStatusQry}&custom_filter=${situationStatus.id}`;
      });
      setReportsWithDetails({
        ...reportsWithDetails,
        loadingWithDetails: true,
      });
      try {
        const response = await requestIntercept(
          api.get,
          `/v2/admin/reports/details?page=${page}&per_page=${perPage}${scaleQry}${situationStatusQry}`,
          {
            filter,
            date_start: dateStart,
            date_end: dateEnd,
            unit_id: unitId,
            user_id: userId,
          }
        );
        if (response.status === 200 || response.status === 201) {
          setReportsWithDetails({
            ...reportsWithDetails,
            listWithDetails: response.data.data,
            loadingWithDetails: false,
            paginationWithDetails: response.data.pagination,
            statusWithDetails: response.status,
            msgWithDetails: response.data.msg,
          });
        } else {
          throw response;
        }
      } catch (err) {
        setReportsWithDetails({
          loadingWithDetails: false,
          statusWithDetails: err.status,
          msgWithDetails: err.msg,
        });
      }
    };

    const getExcelReports = ({ data }) => {
      try {
        let scaleQry = '';
        if (
          data?.scaleIds &&
          Array.isArray(data?.scaleIds) &&
          data?.scaleIds.length > 0
        ) {
          data?.scaleIds.forEach((item) => {
            scaleQry = `${scaleQry}&scale_id=${item.id}`;
          });
        } else {
          scaleQry = `&scale_id=${data?.scaleIds}`;
        }
        const queryData = serialize(data);
        const docUrl = `${api.getBaseURL()}/v1/reports/excel/export?${queryData}${scaleQry}`;
        window.open(docUrl);
      } catch (err) {
        setLoading(false);
      }
    };

    const getExcelUserWithoutAgenda = async (params) => {
      try {
        const queryData = serialize(params);
        const docUrl = `${api.getBaseURL()}/v2/admin/reports/user-without-agenda/export?${queryData}`;
        const response = await requestIntercept(api.get, docUrl, null, {
          responseType: 'blob',
        });

        if (response?.status !== 200 && response?.status !== 201) {
          setLoading(false);
          // reject('Algo deu Errado! Tente novamente mais tarde!');
          throw response;
        }

        const contentType = response.headers['content-type'];
        let filename = 'documento.xlsx';
        const disposition = response.headers['content-disposition'];
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
        fileDownload(response.data, filename, contentType);
      } catch (err) {
        setLoading(false);
      }
    };

    const getProfessionalByHospital = async (unitId, page, perPage, search) => {
      setLoading(true);
      try {
        const response = await requestIntercept(
          api.get,
          unitId !== null
            ? `/v2/admin/users?page=${page}&search=${search}&per_page=${perPage}&unit_id=${unitId}&group_type=professional&status=active`
            : `/v2/admin/users?page=${page}&search=${search}&per_page=${perPage}&group_type=professional&status=active`
        );
        if (response.status !== 200) {
          throw response;
        }
        setDataProfessional(response.data.data);
        setLoading(false);
        setTotalProfessional(response.data.pagination.total_items);
      } catch (err) {
        setLoading(false);
      }
    };

    return {
      reports,
      dataProfessional,
      reportsCheck,
      reportsWithDetails,
      checkWithDetails,
      loading,
      totalProfessional,
      // func
      getCheckInReports,
      getReports,
      getReportsWithDetails,
      getCheckInReportsDetails,
      getProfessionalByHospital,
      getExcelReports,
      getExcelUserWithoutAgenda,
      getProfessionalsWithoutAgenda,
      getProfessionalsWithoutApp,
    };
  };

export default useReport;
